<template>
  <div>
    <div v-if="!content.styleSettings">
      <div class="socials" style="margin-bottom:15px;">
        <div
          style="margin-bottom: 30px">
        <Editor
          api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
          :init="options"
          v-model="content.text"
          @change="setMenu"
        >
        </Editor>
      </div>
        <div class="socials__item" v-for="item in content.content" :key="item.id">
          <div
            class="socials__btn"
            :class="{ active: item.active }"
            @click="addSocial(content.content, item.id)"
          >
            <span
              class="socials__icon"
              :class="{ active: item.active }"
              v-html="item.icon"
            ></span>
            {{ item.text }}
          </div>
          <div class="socials__link" v-if="item.active">
            <v-text-field
              outlined
              solo
              flat
              dense
              hide-details
              :placeholder="placeholderSocial(item.text)"
              v-model="item.link"
              @input="setMenu"
            ></v-text-field>
          </div>
        </div>
      </div>
      <div style="margin-bottom: 15px;">
      <label class="label">Map Settings</label>
      <div style="margin-bottom: 15px">
        <Editor
            api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
            :init="options"
            v-model="content.second_text"
            @change="setMenu"
        >
        </Editor>
      </div>
      <div>
        <div
            class="control"
            style="
                                  display: flex;
                                  justify-content: space-between;
                                "
        >
          <label class="label" style="display: flex; align-items: center"
          >Enable directions button</label
          >
          <v-switch color="#6ab64a" v-model="content.map.enableButton"></v-switch>
        </div>
      </div>
      <div v-if="content.map.enableButton">

        <div
            style="
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-between;
                                  margin-bottom: 15px;
                                "
            class="field"
        >
          <label>Name</label>
          <v-text-field
              dense
              hide-details
              outlined
              style="max-width: 200px"
              v-model="content.map.directionsText"
          ></v-text-field>
        </div>
        <div>
          <div
              style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 15px;"
              class="field"
          >
            <label>Address</label>
            <v-autocomplete
              dense
              flat
              outlined
              solo
              class="input input_dense"
              placeholder="Write your address"
              :items="locationFindedBtn"
              item-value="place_id"
              item-text="formatted_address"
              @input="getLocationBtn"
              @update:search-input="searchLocationBtn"
              hide-details
              style="max-width:200px;"
              v-model="locationModelBtn"
              return-object
          ></v-autocomplete>
          </div>
        </div>
        <div
            style="
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-between;
                                  margin-bottom: 15px;
                                "
            class="field"
        >
          <label>Longitude</label>
          <v-text-field
              dense
              hide-details
              outlined
              style="max-width: 200px"
              v-model="content.map.lng_btn"
              @input="content.map.lng = content.map.lng_btn"
          ></v-text-field>
        </div>
        <div
            style="
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-between;
                                  margin-bottom: 15px;
                                "
            class="field"
        >
          <label>Latitude</label>
          <v-text-field
              dense
              hide-details
              outlined
              style="max-width: 200px"
              v-model="content.map.lat_btn"
              @input="content.map.lat = content.map.lat_btn"
          ></v-text-field>
        </div>
      </div>
      <div>
        <div
            class="control"
            style="
                                  display: flex;
                                  justify-content: space-between;
                                "
        >
          <label class="label" style="display: flex; align-items: center"
          >Enable Map</label
          >
          <v-switch color="#6ab64a" v-model="content.map.show"></v-switch>
        </div>
      </div>
      <div v-if="content.map.show">
        <div
            style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 15px;"
            class="field"
        >
          <label>Address</label>
          <v-autocomplete
              dense
              flat
              outlined
              solo
              class="input input_dense"
              placeholder="Write your address"
              :items="locationFinded"
              item-value="place_id"
              item-text="formatted_address"
              @input="getLocation"
              @update:search-input="searchLocation"
              hide-details
              style="max-width:200px;"
              v-model="locationModel"
              return-object
          ></v-autocomplete>
        </div>
        <div
            style="
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-between;
                                  margin-bottom: 15px;
                                "
            class="field"
        >
          <label>Longitude</label>
          <v-text-field
              dense
              hide-details
              outlined
              style="max-width: 200px"
              v-model="content.map.lng"
              @input="content.map.lng_btn = content.map.lng"
          ></v-text-field>
        </div>
        <div
            style="
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-between;
                                  margin-bottom: 15px;
                                "
            class="field"
        >
          <label>Latitude</label>
          <v-text-field
              dense
              hide-details
              outlined
              style="max-width: 200px"
              v-model="content.map.lat"
              @input="content.map.lat_btn = content.map.lat"
          ></v-text-field>
        </div>
        <div class="field">
          <label>Change zoom range {{ content.map.zoom }}</label>
          <v-slider
              :max="18"
              :min="0"
              hide-details
              v-model="content.map.zoom"
              @end="setMenu"
          ></v-slider>
        </div>
        <div class="field" style="margin-bottom: 15px">
          <div
              class="control"
              style="
                                    display: flex;
                                    justify-content: space-between;
                                  "
          >
            <label class="label" style="display: flex; align-items: center"
            >Enable zoom control</label
            >
            <v-switch color="#6ab64a" v-model="content.map.zoomControl"></v-switch>
          </div>
        </div>
        <div class="field" style="margin-bottom: 15px">
          <div
              class="control"
              style="
                                    display: flex;
                                    justify-content: space-between;
                                  "
          >
            <label class="label" style="display: flex; align-items: center"
            >Enable fullscreen control</label
            >
            <v-switch color="#6ab64a" v-model="content.map.fullscreenControl"></v-switch>
          </div>
        </div>
        <div class="field" style="margin-bottom: 15px">
          <div
              class="control"
              style="
                                    display: flex;
                                    justify-content: space-between;
                                  "
          >
            <label class="label" style="display: flex; align-items: center"
            >Enable map type control</label
            >
            <v-switch color="#6ab64a" v-model="content.map.mapTypeControl"></v-switch>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div v-if="content.styleSettings">
      <ContactStyleSettings :content="content"/>
    </div>
    <SliderMenu
      :slider_menu="content.slider"
      :active_slider="content"
      :menu="menu"
    />
  </div>
</template>

<script>
import SliderMenu from "./SliderMenu";
import { mapState } from "vuex";
import {gmapApi} from "vue2-google-maps";

export default {
  name: "typeContact",
  props: ["content",'item'],
  components: {
    ContactStyleSettings:() => import("./Contact/ContactStyleSettings.vue"),
    SliderMenu,
  },
  data() {
    return {
      locationModelBtn:'',
      locationFindedBtn:[],
      locationModel:'',
      locationFinded:[],
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 200,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",
      setup:function(editor){
              editor.ui.registry.addButton('custom_button', {
                  text: 'Add Button',
                  onAction: function() {
                      // Open a Dialog
                      editor.windowManager.open({
                          title: 'Add custom button',
                          body: {
                              type: 'panel',
                              items: [{
                                  type: 'input',
                                  name: 'button_label',
                                  label: 'Button label',
                                  flex: true
                              },{
                                  type: 'input',
                                  name: 'button_href',
                                  label: 'Button href',
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_target',
                                  label: 'Target',
                                  items: [
                                      {text: 'None', value: ''},
                                      {text: 'New window', value: '_blank'},
                                      {text: 'Self', value: '_self'},
                                      {text: 'Parent', value: '_parent'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_style',
                                  label: 'Style',
                                  items: [
                                      {text: 'Success', value: 'success'},
                                      {text: 'Info', value: 'info'},
                                      {text: 'Warning', value: 'warning'},
                                      {text: 'Error', value: 'error'}
                                  ],
                                  flex: true
                              }]
                          },
                          onSubmit: function (api) {

                              var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                              // insert markup
                              editor.insertContent(html);

                              // close the dialog
                              api.close();
                          },
                          buttons: [
                              {
                                  text: 'Close',
                                  type: 'cancel',
                                  onclick: 'close'
                              },
                              {
                                  text: 'Insert',
                                  type: 'submit',
                                  primary: true,
                                  enabled: false
                              }
                          ]
                      });
                  }
              });
          },
      content_css: ["/custom_tinymce.css"],
      },
    };
  },
  computed: {
    ...mapState(["landing"]),
    ...mapState("landing", ["menu"]),
    google:gmapApi
  },
  methods: {
    getLocationBtn(val){
      this.content.map.lng_btn = val.geometry.location.lng()
      this.content.map.lat_btn = val.geometry.location.lat();
    },
    searchLocationBtn(value){
      const geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({'address': value}, (results, status) => {
        if (status === 'OK') {
          this.locationFindedBtn.push(...results);
        }
      });
    },
    getLocation(val){
      this.content.map.lng = val.geometry.location.lng()
      this.content.map.lat = val.geometry.location.lat();
    },
    searchLocation(value){
      const geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({'address': value}, (results, status) => {
        if (status === 'OK') {
          this.locationFinded.push(...results);
        }
      });
    },
    placeholderSocial(text){
      if(text === 'Whatsapp' || text === 'Viber' || text === 'Telegram'){
        return 'Write your nickname'
      }
      if(text === 'Custom link'){
        return 'Write link with https://'
      }
      if(text === 'Email'){
        return 'Write your email'
      }
      if(text === 'Telephone'){
        return 'Write your phone number'
      }
      return 'Insert your link'
    },
    forceRerender() {
      // Removing my-component from the DOM
      this.$store.state.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.$store.state.renderComponent = true;
      });
    },
    openCopy(){
      this.$refs.PopupConfirm.open();
    },
    setMenu() {
      this.$store.commit("landing/setMenu", this.menu);
    },
    addSocial(arr, id) {
      arr.forEach((social) => {
        if (social.id === id) {
          social.active = !social.active;
        }
      });
      this.setMenu();
    },
  },
  mounted(){
  }
};
</script>

<style lang="scss" scoped></style>
